import React from 'react';
import './App.css';
import Home from './pages/Home';
import Nav from './components/Nav';
import Footer from './components/Footer'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import About from './pages/About';
import Commissions from './pages/Commissions';


function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/commissions" element={<Commissions />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
