import React from 'react';
import styled from 'styled-components';

const Commissionsinfo = ({ image }) => {
    return (
        <section>
            <Container>
                <Row>
                    <img src={image} alt="" />
                </Row>
            </Container>
        </section>
    );
}

export default Commissionsinfo;

const Container = styled.div`
    padding: 56px 0;
`

const Row = styled.div`
    section {
        margin-bottom: 32px;
    }

    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    img {
        width: 100%;
        max-width: 700px;
        height: 100%
    }
`