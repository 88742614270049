import React from 'react';
import Bio from '../components/Bio'
import Contact from '../components/Contact'

const About = () => {
    return (
        <>
            <Bio />
            <Contact />
        </>
    );
}

export default About;
