import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div>
            <FooterContainer>
                <Row>
                    <img src="/assets/pixlr-bg-result.PNG" alt="footerlogo" />
                    <FooterLinks>
                        <Link to="/" >Home</Link>
                        <Link to="/about" >About</Link>
                        <Link to="/commissions" >Info</Link>
                    </FooterLinks>
                    <p>Made by Henry Le</p>
                </Row>
            </FooterContainer>
        </div>
    );
}

export default Footer;

const FooterContainer = styled.footer`
    padding: 56px 0;
    background-color: #242424;
    color: white;
    text-align: center;
`
const FooterLinks = styled.div`
    a {
        text-decoration: none;
        color: white;
        padding: 0 24px;
    }

    a:hover {
        color: #FF581C;
        transition: all 300ms ease;
    }
    margin-bottom: 16px;
`
const Row = styled.div`
    width: 100%;
    max-width: 700px;
    margin: 0 auto;

    img {
        margin-bottom: 16px;
        width: 100px;
    }
`