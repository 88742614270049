import React from 'react';
import styled from 'styled-components'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

const Favorites = () => {
    return (
        <FavContainer>
            <Row>
                <h1>- Favorite Designs -</h1>
            </Row>
            <Carousel>
                <AliceCarousel autoPlay infinite autoPlayInterval="3000">
                    <img src="/assets/dragon.jpg" className="sliderimg" alt="" />
                    <img src="/assets/eren.jpg" className="sliderimg" alt="" />
                    <img src="/assets/mushroom.jpg" className="sliderimg" alt="" />
                    <img src="/assets/IMG_5101.jpeg" className="sliderimg" alt="" />
                    <img src="/assets/egg.jpg" className="sliderimg" alt="" />
                    <img src="/assets/amongus.jpg" className="sliderimg" alt="" />
                    <img src="/assets/hisoka.jpg" className="sliderimg" alt="" />
                    <img src="/assets/kirby.jpg" className="sliderimg" alt="" />
                </AliceCarousel>
            </Carousel>
        </FavContainer>
    );
}

export default Favorites;

const FavContainer = styled.div`
    padding: 56px 0;
`

const Row = styled.div`
    width: 100%;
    max-width: 700px;
    margin: 0 auto;

    h1 {
        text-align: center;
        margin: 16px 0;
    }
`

const Carousel = styled.div`
    
`