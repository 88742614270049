import React from 'react';
import Commissionsinfo from '../components/CommissionsInfo';
import styled from 'styled-components';

const Commissions = () => {
    return (
        <>
            <SectionTitle>
                <h1>Commissions Info</h1>
            </SectionTitle>
            <Commissionsinfo image="/assets/1.JPEG" />
            <Commissionsinfo image="/assets/2.JPEG" />
            <Commissionsinfo image="/assets/3.JPEG" />
            <Commissionsinfo image="/assets/4.JPEG" />
        </>
    );
}

export default Commissions;

const SectionTitle = styled.div`
    padding-top: 56px;
    text-align: center;

    h1 {
        font-weight: 600;
        margin-bottom: 16px;
    }

`