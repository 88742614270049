import React from 'react';
import { TikTok } from 'react-tiktok';
import styled from 'styled-components';

const Bio = () => {
    return (
        <Container>
            <Row>
                <section>
                    <SectionTitle>
                        <h1>About Me</h1>
                    </SectionTitle>
                    <AboutText>
                        <BioText>
                            <p>My name is Paul Nguyen. I created <span id="orange">Jr. Rugs</span> in 2021. I'm a college student and I love watching anime. I grew up watching and drawing anime when I was younger and it one of the reasons why I decided to start making rugs. I still try to watch anime, but I'm always busy with either work or school.</p>
                        </BioText>
                        <Reference>
                            <p>My good friend Christina <span id="orange">(@chris.crystals)</span> was also a big part of the reason why I decided to take this big step in my life! She also has her own business where she sells crystals, you guys should check her out!</p>
                        </Reference>
                    </AboutText>
                    <ul className="TestTikTok">
                        <li>
                            <TikTok url="https://www.tiktok.com/@jr_rugs/video/7057982670470696238" />
                        </li>
                    </ul>
                </section>
            </Row>
        </Container>
    );
}

export default Bio;

const Container = styled.div`
    padding: 56px 0;
`

const Row = styled.div`
    section {
        margin-bottom: 32px;
    }
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;

    li {
        list-style-type: none;
    }
`

const SectionTitle = styled.div`
    text-align: center;

    h1 {
        font-weight: 600;
        margin-bottom: 16px;
    }

`

const BioText = styled.div`
    display: flex;
    justify-content: center;

    p {
        width: 67%;
        margin-right: 16px;
        font-size: 20px;
    }

    img {
        width: 33%;
    }

    ul {
        max-height: 100px;
    }

    li {
        max-height: 100px;
    }
    
    margin-bottom: 32px;
`

const Reference = styled.div`
    display: flex;
    justify-content: center;
    p {
        width: 67%;
        margin-right: 16px;
        font-size: 20px;
    }

    img {
        width: 33%;
    }
    
    margin-bottom: 64px;
`
const AboutText = styled.div`
    
`