import React from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';

export default function Contact() {
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_oud7ogr', 'template_thf44sd', e.target, 'user_DRxJ18uk3xgkYakVhvZLw')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
                alert('The email service is temporarily unavailable. Please contact me directly at jrrugs.cs@gmail.com.');
            });
        e.target.reset();
    }
    return (
        <Container>
            <Row>
                <h3>Contact Me!</h3>
                <form onSubmit={sendEmail} id="contact-form">
                    <Item>
                        <div class="form-item">
                            <label class="form-item-label">Name</label>
                            <input type="text" class="input" name="user_name" required></input>
                        </div>
                    </Item>
                    <Item>
                        <div class="form-item">
                            <label class="form-item-label">Email</label>
                            <input type="email" class="input" name="user_email" required></input>
                        </div>
                    </Item>
                    <Item>
                        <div class="form-item">
                            <label class="form-item-label">Message</label>
                            <textarea type="text" class="input" name="message" required></textarea>
                        </div>
                    </Item>
                    <Button>
                        <button id="contact-submit" class="form-submit">Send it my way!</button>
                    </Button>
                </form>
            </Row>
        </Container>
    );
}

const Button = styled.div`
    button {
        background-color: #FF581C;
        color: white;
        border: 2px solid #FF581C;
        font-weight: 700;
        max-width: 240px;
        width: 100%;
        padding: 12px 24px;
        font-size: 20px;
        cursor: pointer;
        transition: all 300ms ease;
    }

    button:hover {
        background-color: transparent;
        color: #FF581C;
    }

    display: flex;
    justify-content: center;
`

const Item = styled.div`
    margin-bottom: 20px;

    .form-item {
        display: flex;
        flex-direction: column;
    }

    input, textarea {
        width: 100%;
        outline: none;
        border: 3px solid #c8c4cc;
        border-top: none;
        border-left: none;
        border-right: none;
        height: 40px;
        transition: all 300ms ease;
    }

    textarea {
        height: 100px;
    }
`
const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 56px 0;
    margin: 0 auto;
    width: 400px;
    flex-direction: column;

    h3 {
        margin-bottom: 16px;
    }

    form {
        width: 100%;
    }
`
const Row = styled.div`
    width: 100%;
    max-width: 300px;
`