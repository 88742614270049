import React from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const Landing = () => {
    return (
        <section>
            <Header>
            <Container>
                <img src="/assets/pixlr-bg-result.PNG" alt="" />
                <Text>
                    <h1>
                        Custom Handmade Rugs
                    </h1>
                    <h1>
                        All rugs are <span id="orange">1 of 1</span> and will not be duplicated!
                    </h1>
                    <h1>
                        Commissions open the <span id="orange">1st and 16th of every month</span>
                    </h1>
                    <h1>
                        Themed drops announced on <span id="orange">Instagram</span>!
                    </h1>
                    <h1>
                        Next themed drop is: <span id="orange">Haikyuu</span>
                    </h1>
                </Text>
                <Socials>
                    <a target="_blank" href="https://tiktok.com/@jr_rugs">
                        <Icon icon="fa-brands:tiktok" />
                    </a>
                    <a target="_blank" href="https://instagram.com/jr_rugs">
                        <Icon icon="akar-icons:instagram-fill" />
                    </a>
                    <a target="_blank" href="https://www.twitch.tv/iuapjr">
                        <Icon icon="akar-icons:twitch-fill" />
                    </a>               
                    <a target="_blank" href="https://www.youtube.com/channel/UCx8q-v_Ob40ZLDizT7jBCug">
                        <Icon icon="ant-design:youtube-outlined" />
                    </a>
                </Socials>
                <Btn>
                    <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdpWIXhKN---7GOT3jSJwZ6A1ZQtavZfaaW4jrp-loWR1FFeQ/viewform">Commissions</a>
                </Btn>
            </Container>
        </Header>
        </section>
    );
}

export default Landing;

const Header = styled.div`
    
`

const Container = styled.div`
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 56px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
    img {
        width: 300px;
        margin-bottom: 8px;
    }
`

const Text = styled.div`
    text-align: center;
    padding-top: 16px;
    font-size: 16px;
    margin-bottom: 16px;
`

const Socials = styled.div`
    a {
        color: black;
        font-size: 24px;
        padding: 0 16px;
    }

    a:hover {
        color: #FF581C;
        transition: all 300ms ease;
    }

    margin-bottom: 24px;
`
const Btn = styled.div`
    a {
        text-decoration: none;
        background-color: #FF581C;
        color: white;
        padding: 8px 36px;
        border-radius: 8px;
        font-size: 24px;
    }

    a:hover {
        background-color: white;
        color: #FF581C;
        border-radius: 8px;
        border: 1px solid #FF581C;
        transition: all 300ms ease;
    }

    cursor: pointer;
`