import React from 'react';
import Favorites from '../components/Favorites';
import Landing from '../components/Landing';

const Home = () => {
    return (
        <>
            <Landing />
            <Favorites />
        </>
    );
}

export default Home;
