import React, { useState } from 'react';
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

const Nav = () => {
    const [burgerStatus, setBurgerStatus] = useState(false);

    return (
        <NavBar>
            <h1>Jr Rugs</h1>
            <NavMenu>
                <button onClick={() => setBurgerStatus(true)}>
                    <FontAwesomeIcon icon="fa-solid fa-bars" />
                </button>
                <BurgerNav show={burgerStatus}>
                <CloseWrapper>
                    <CloseButton onClick={() => setBurgerStatus(false)}/>
                </CloseWrapper> 
                <ul>
                    <li>
                        <Link onClick={() => setBurgerStatus(false)} to="">Home</Link>
                    </li>
                    <li>
                        <Link onClick={() => setBurgerStatus(false)} to="/about">About Me</Link>
                    </li>
                    <li>
                        <Link to="/commissions" onClick={() => setBurgerStatus(false)}>Commission Info</Link>
                    </li>
                </ul>
            </BurgerNav>
            </NavMenu>
        </NavBar>
    );
}

export default Nav;

const NavBar = styled.nav`
    padding: 0 56px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        font-size: 48px;
    }

    button {
        font-size: 32px;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
    }

    button:hover {
        color: #FF581C;
    }

    border-bottom: solid 1px gray;
`

const NavMenu = styled.div`
    
`

const BurgerNav = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background: white;
    width: 400px;
    z-index: 16;
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: start;
    transform: ${props => props.show ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.2s;
    border: 1px solid black;

    ul {
        padding: 24px 0 0 24px;
        list-style-type: none;
    }

    li {
        padding: 16px 0;
    }

    a {
        font-weight: 700px;
        text-decoration: none;
        color: black;
        font-size: 24px;
    }

    a:hover {
        text-decoration: underline;
        color: #FF581C;
    }
`

const CloseButton = styled(CloseIcon)`
    cursor: pointer;
`

const CloseWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
`